<template>
  <div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h5>
                        الاخبار
                        <button class="btn btn-primary" v-b-modal.add style="padding: 10px; padding-top: 8px; padding-right: 8px; border-radius: 50%; height: 30px; width: 30px;">
                            <i class="fa fa-plus"></i>
                        </button>
                    </h5>
                </div>
                <div class="card-footer">
                    <div class="col-12 table-responsive">
                        <table class="table table-sm table-bordered table-hover">
                            <thead>
                                <th>
                                    العنوان
                                </th>
                                <th>
                                    تاريخ النشر
                                </th>
                                <th>
                                    خيارات
                                </th>
                            </thead>
                            <tbody>
                                <tr v-for="item in items" :key="item._id">
                                    <td>
                                        {{ item.title }}
                                    </td>
                                    <td>
                                        {{ item.date }}
                                    </td>
                                    <td>
                                        <b-dropdown dropleft style="width: 100%;"
                                            id="dropdown-1x"
                                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                            text="خيارات"
                                            variant="secondary"
                                            size="sm"
                                        >
                                            <b-dropdown-item @click="current = item" v-b-modal.edit>
                                                <i class="fa fa-edit"></i>
                                                تعديل 
                                            </b-dropdown-item>
                                            <b-dropdown-item style="font-size: 12px" @click="deleteUser(item._id)">
                                                <i class="fa fa-trash"></i>
                                                حذف 
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="add" title="اضافة " hide-footer>
        <div class="form-group">
        <label for="">
            العنوان
        </label>
        <input type="text"
            class="form-control" v-model="create.title">
        </div>
        <div class="form-group">
          <label for="">المحتوى</label>
          <textarea class="form-control" v-model="create.content" rows="3"></textarea>
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="createNow()">
                اضافة السكن
            </button>
        </div>
    </b-modal>
    <b-modal id="edit" title="تعديل" hide-footer>
        <template v-if="current._id">
        <div class="form-group">
        <label for="">
            العنوان
        </label>
        <input type="text"
            class="form-control" v-model="current.title">
        </div>
        <div class="form-group">
          <label for="">المحتوى</label>
          <textarea class="form-control" v-model="current.content" rows="3"></textarea>
        </div>
            <div class="col-12 text-center g">
                <button class="btn btn-primary" @click="save()">
                    تعديل الان
                </button>
            </div>
        </template>
    </b-modal>
  </div>
</template>

<script>
import {  VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BModal
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            current: {},
            items: [],
            user: JSON.parse(localStorage.getItem("user")),
            create: {}
        }
    },
    created(){
        if(!checkPer('posts')){
            this.$router.push('/pers')
            return;
        }
        this.getcamps()
    },
    methods: {
        getcamps(){
            var g = this;
            $.post(api + '/admin/web/posts/list', {
                jwt: g.user.jwt
            }).then(function(r){
                g.items = r.response;
            })
        },
        createNow(){
            var g = this;
            $.post(api + '/admin/web/posts/add', {
                jwt: g.user.jwt,
                title: g.create.title,
                content: g.create.content
            }).then(function(r){
                g.getcamps();
                alert("تم الاضافة بنجاح", 100)
            })
        },
        deleteUser(id){
            if(confirm("متأكد من الحذف؟")){
                var g = this;
                $.post(api + '/admin/web/posts/delete', {
                    jwt: g.user.jwt,
                    id: id
                }).then(function(a){
                    g.getcamps();
                    alert("تم الحذف بنجاح", 100);
                })
            }
        },
        save(){
            var g = this;
            $.post(api + '/admin/web/posts/edit', {
                jwt: g.user.jwt,
                id: g.current._id,
                title: g.current.title,
                content: g.current.content
            }).then(function(a){
                g.getcamps();
                alert("تم التعديل بنجاح", 100);
            })
        }
    }
}
</script>

<style>
#dropdown-1x__BV_toggle_{
    padding: 0px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    border-radius: 0px;
}
</style>